<template>
  <f7-page>
    <f7-navbar title="出错啦" back-link></f7-navbar>
  </f7-page>
</template>

<script>
export default {
  name: "notfound",
};
</script>
